import React from "react";
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import Select from 'react-select/creatable';
import AsyncSelect from 'react-select/async-creatable';
import RegularSelect from 'react-select';
import { Link } from "react-router-dom";

class GranularAccessForm extends React.Component {

  constructor(props) {
    super(props);

  }

  renderAttributeValInput(id, type) {
    let value = this.props.formState.attributeInputValues[type][id];

    if (typeof value === 'object') {
      value = '';
    }

    return (
      <input type="text" className="form-control" 
             disabled={true}
            value={value}/>
    )
  }

  renderAttributeRegexInput(id, type) {
    let regex_value = this.props.formState.attributeRegexInputValues[type][id];
    let multi_value = this.props.formState.attributeMultiValueInputValues[type][id];

    const options = [
      { value: 'single', label: 'Single value' },
      { value: 'multi_value', label: 'Multi value' },
      { value: 'regex', label: 'Regex' },
    ]

    let select_value = { value: 'single', label: 'Single value' };

    if (multi_value) {
      select_value = { value: 'multi_value', label: 'Multi value' };
    } else if (regex_value) {
      select_value = { value: 'regex', label: 'Regex' };
    }


    return (
      <React.Fragment>
        <div className="gran_actions_select_box">
          <RegularSelect
            options={options}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            value={select_value}
            isDisabled={true}
          />
        </div>
      </React.Fragment>
    )
  }

  renderBox(id, type='allow') {
    let attributeSelectValue = this.props.formState.attributeSelectValues[type][id];
    let attributeInputDom = this.renderAttributeValInput(id, type);
    let attributeRegexInputDom = this.renderAttributeRegexInput(id, type);
    let regexEnabled = this.props.formState.attributeRegexInputValues[type][id];
    let multiValue = this.props.formState.attributeMultiValueInputValues[type][id];

    if (attributeSelectValue != null && regexEnabled != true) {
      if (attributeSelectValue.value == "sAMAccountName") {
        attributeInputDom = this.renderUsernameSearchBox(id, type, multiValue);
      } else if (attributeSelectValue.value == 'groups') {
        attributeInputDom = this.renderGroupSearchBox(id, type, multiValue);
      }
    }


    return (
      <div className="gran-select-box" key={id}>
        <div className="gran_select">
          <Select 
            options={this.props.formState.attributeOptions}
            isClearable={false}
            isSearchable={false} 
            isDisabled={true}
            value={attributeSelectValue}
            />
        </div>
        <div className="gran_input">
          {attributeInputDom}
        </div>

        <div className="gran_actions">
          {attributeRegexInputDom}
        </div>

        <div className="gran_clear" />
      </div>  
    )
  }

  renderAllowBoxes() {
    let allow_boxes = [];
    for (var i=0; i< this.props.formState.allow_attribute_boxes; i++) {
      allow_boxes.push(i)
    }

    return (
      <React.Fragment>
        {allow_boxes.map(function(value, index) {
          return this.renderBox(value, 'allow');
        }.bind(this))}
      </React.Fragment>
    )
  }

  renderDenyBoxes() {
    let deny_boxes = [];
    for (var i=0; i< this.props.formState.deny_attribute_boxes; i++) {
      deny_boxes.push(i)
    }

    return (
      <React.Fragment>
        {deny_boxes.map(function(value, index) {
          return this.renderBox(value, 'deny');
        }.bind(this))}
      </React.Fragment>
    )
  }

  renderPredefinedBox(id) {
    let predefined_rules = this.props.formState.predefined_rules.map(function(rule, index) {
      return {value: rule['id'], label: rule['rule_name']};
    });


    return (
      <div className="row gran-select-box" key={id}>
        <div className="col-sm-12">
        <RegularSelect
          options={predefined_rules}
          isClearable={false}
          isSearchable={false}
          isMulti={false}
          isDisabled={true}
          value={this.props.formState.attributeInputValues['predefined'][id]}
        />
        </div>
      </div>  
    )
  }

  renderPredefinedBoxes() {
    let predefined_boxes = [];
    for (var i=0; i< this.props.formState.predefined_boxes; i++) {
      predefined_boxes.push(i)
    }

    return (
      <React.Fragment>
        {predefined_boxes.map(function(value, index) {
          return this.renderPredefinedBox(value);
        }.bind(this))}
      </React.Fragment>
    )
  }

  renderMfaPurchaseRequired() {
    if (!gon.mfa_integrated) {
      return(
        <p>
          To use this feature you need to purchase the Overt MFA service. 
          &nbsp;
          <a href="https://overtmfa.com" target="_blank">
            Click here to trial it for free
          </a>
        </p>
      )
    }

    return (
      <React.Fragment>
      </React.Fragment>
    )
  }

  renderPredefinedValueBox(value, type='allow') {
    const options = [
      { value: 'single', label: 'Single value' },
      { value: 'multi_value', label: 'Multi value' },
      { value: 'regex', label: 'Regex' },
    ];

    let select_value = { value: 'single', label: 'Single value' };

    if (value['multi_value']) {
      select_value = { value: 'multi_value', label: 'Multi value' };
    } else if (value['regex']) {
      select_value = { value: 'regex', label: 'Regex' };      
    }

    return (
      <div className="gran-select-box">
        <div className="gran_select">
          <Select 
            options={this.props.formState.attributeOptions}
            isClearable={false}
            isSearchable={false} 
            value={{label: value['attribute'],value: value['attribute']}}
            isDisabled={true}
            />
        </div>
        <div className="gran_input">
          <input type="text" className="form-control" 
                 value={value['values']} disabled={true}/>        
        </div>

        <div className="gran_actions">
          <div className="gran_actions_select_box">
            <RegularSelect
              options={options}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              value={select_value}
              isDisabled={true}
            />
          </div>
        </div>          

        <div className="gran_clear" />
      </div>  
    )
  }

  renderPredefinedValues(type='allow') {
    var css_class = "allow_group";
    var title_start = "Allow conditions from ";
    var rules = {};

    if (type == 'allow') {
      rules = this.props.formState.predefined_values.predefined_allow;
    } else  {
      title_start = "Deny conditions from ";
      rules = this.props.formState.predefined_values.predefined_deny; 
      css_class = "deny_group";
    }

    var rule_keys = Object.keys(rules);
    return (
      <React.Fragment>
        {rule_keys.map(
          function(rule_value, index) {
            if (rules[rule_value].length < 1) {
              return;
            }

            return (
              <div className={css_class} key={index}>
                <div className="group_background" />
                <strong>{title_start} "{rule_value}" predefined set</strong>

                {rules[rule_value].map(function(value, value_index) {
                  return (
                    <React.Fragment key={value_index}>
                      {this.renderPredefinedValueBox(value, type)}
                    </React.Fragment>
                  )
                }.bind(this))}
              </div>
            );
          }.bind(this)
        )}
      </React.Fragment>
    )
  }

  promiseUsernameOptions = inputValue => {
    return new Promise(resolve => {
      resolve(this.props.searchUsername(inputValue))
    })
  }

  renderUsernameSearchBox(id, type, multiValue) {
    let value = this.props.formState.attributeInputValues[type][id];

    let values = null;
    if (typeof value === 'object') {
      value = null;
    } else if (multiValue) {
      values = value.split(",").map(function(value, index) {
        return {value: value, label: value}
      }).filter(value => value.value != null && value.value != '');
    } else {
      values = {value: value, label: value};
    }

    return (
      <AsyncSelect 
        cacheOptions={true}
        isClearable={false}
        isSearchable={false} 
        isMulti={multiValue}
        loadOptions={this.promiseUsernameOptions}
        value={values}
        isDisabled={true}
        />
    )    
  }


  promiseGroupOptions = inputValue => {
    return new Promise(resolve => {
      resolve(this.props.searchGroup(inputValue))
    })
  }

  renderGroupSearchBox(id, type, multiValue) {
    let value = this.props.formState.attributeInputValues[type][id];

    let values = null;
    if (typeof value === 'object') {
      value = null;
    } else if (multiValue) {
      values = value.split(",").map(function(value, index) {
        return {value: value, label: value}
      }).filter(value => value.value != null && value.value != '');
    } else {
      values = {value: value, label: value};
    }


    return (
      <AsyncSelect 
        cacheOptions={true}
        isClearable={false}
        isSearchable={false} 
        isMulti={multiValue}
        loadOptions={this.promiseGroupOptions}
        value={values}
        isDisabled={true}
        />
    )    
  }

  render() {
    if (this.props.formState.caughtError) {
      throw this.props.formState.caughtError;
    }

    if (this.props.formState.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.props.formState.loading} />
        </div>
      );
    }

    var currentDefaultAction = 'Allow access, bypassing multi-factor ' + 
                               'authentication';

    if (this.props.formState.defaultAction == 'allow_with_mfa') {
      currentDefaultAction = "Allow access, using multi-factor authentication"+
                             " if the user has any MFA tokens";
    } else if (this.props.formState.defaultAction == 'allow_require_mfa') {
      currentDefaultAction = "Allow access, requiring multi-factor" +
                             "  authentication  even if the user has no" + 
                             " MFA tokens";
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12 granularaccess_form">
            <RenderError error_message={this.props.formState.error_message} />
            <form onSubmit={this.props.onSubmit}>
              <div className="form-group">
                <label><h6>Rule name</h6></label>
                <input type="text" id="rule_name" 
                       className="form-control" required={true}
                       onChange={this.props.handleChange}
                       value={this.props.formState.rule_name} />
              </div>

              <div className="form-group">
                <label><h6>Service provider(s)</h6></label>
                <Select 
                  options={this.props.formState.entityIds}
                  isClearable={false}
                  isSearchable={false} 
                  isMulti={true}
                  onChange={this.props.handleEntityIdChange}
                  value={this.props.formState.enttiyIdSelect}
                  isDisabled={true}
                />
              </div>

              <div className="form-group">
                <label><h6>Comments</h6></label>
                <textarea id="comments" className="form-control"
                          disabled={true}
                          value={this.props.formState.comments} />
              </div>

             <div className="form-group">
                <h6>Predefined sets</h6>

                {this.renderPredefinedBoxes()}
             </div>

             <hr />

             <div className="form-group">
                <h6>Allow conditions</h6>

                <div className="form-check">
                  <input type="radio" className="form-check-input"
                         name="allow_radio" value="AND" 
                         checked={this.props.formState.allow_radio === 'AND'}
                         disabled={true}
                         />
                  <label className="form-check-label">
                    Allow if all of the below conditions match
                  </label>
                </div>

                <div className="form-check">
                  <input type="radio" className="form-check-input"
                         name="allow_radio" value="OR"
                         checked={this.props.formState.allow_radio === 'OR'}
                         disabled={true}
                          />
                  <label className="form-check-label">
                    Allow if any of the below conditions match
                  </label>
                </div>

                {this.renderPredefinedValues('allow')}
                <div className="allow_group">
                  <div className="group_background" />
                  <strong>Manually added allow conditions</strong>
                  {this.renderAllowBoxes()}
                </div>
              </div>
              <hr />
              <div className="form-group">
                <h6>Deny conditions</h6>
                <div className="form-check">
                  <input type="radio" className="form-check-input"
                         name="deny_radio" value="AND" 
                         checked={this.props.formState.deny_radio === 'AND'}
                         disabled={true}
                         />
                  <label className="form-check-label">
                    Deny if all of the below conditions match
                  </label>
                </div>

                <div className="form-check">
                  <input type="radio" className="form-check-input"
                         name="deny_radio" value="OR"
                         checked={this.props.formState.deny_radio === 'OR'}
                         disabled={true}
                          />
                  <label className="form-check-label">
                    Deny if any of the below conditions match
                  </label>
                </div>

                {this.renderPredefinedValues('deny')}
                <div className="deny_group">
                  <div className="group_background" />
                  <strong>Manually added deny conditions</strong>                
                  {this.renderDenyBoxes()}
                </div>

              </div>
             <hr />
             <div className="form-group">
              <h6>Action if a user is allowed access</h6>
              {this.renderMfaPurchaseRequired()}

              {this.props.formState.defaultAction != 'deny' && 
                <div className="form-check">
                  <input type="radio" className="form-check-input"
                         name="mfaStepUp" id="mfaStepUp" value='default' 
                         checked={
                           (
                             this.props.formState.mfaStepUp === 'default' ||
                             this.props.formState.mfaStepUp == null ||
                             this.props.formState.mfaStepUp.length < 1                             
                           )
                         }
                         onChange={this.props.handleChange}
                         disabled={true}
                         />
                  <label className="form-check-label">
                    Perform default action ({currentDefaultAction})
                  </label>
                </div>
              }


              <div className="form-check">
                <input type="radio" className="form-check-input"
                       name="mfaStepUp" id="mfaStepUp" value='allow' 
                       checked={
                         (this.props.formState.mfaStepUp=== 'allow' || 
                          (
                            this.props.formState.defaultAction == 'deny' &&
                            (
                              this.props.formState.mfaStepUp == null ||
                              this.props.formState.mfaStepUp.length < 1
                            )
                          )
                         )
                       }
                       onChange={this.props.handleChange}
                       disabled={true}
                       />
                <label className="form-check-label">
                  Allow access, bypassing multi-factor authentication
                </label>
              </div>
              <div className="form-check">
                <input type="radio" className="form-check-input"
                       name="mfaStepUp" id="mfaStepUp" value='allow_with_mfa' 
                       checked={this.props.formState.mfaStepUp=== 'allow_with_mfa'}
                       onChange={this.props.handleChange}
                       disabled={true}
                       />
                <label className="form-check-label">
                  Allow access, using multi-factor authentication if the user 
                  has any MFA tokens
                </label>
              </div>    
              <div className="form-check">
                <input type="radio" className="form-check-input"
                       name="mfaStepUp" id="mfaStepUp" value='allow_require_mfa' 
                       checked={this.props.formState.mfaStepUp=== 'allow_require_mfa'}
                       onChange={this.props.handleChange}
                       disabled={true}
                       />
                <label className="form-check-label">
                  Allow access, requiring multi-factor authentication even if 
                  the user has no MFA tokens
                </label>
              </div>                          
             </div>
             <p>
               &nbsp;
              <Link to="/" className="btn btn-default">
                Go back
              </Link>     
             </p>                       
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }  
}

export default GranularAccessForm
